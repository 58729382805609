import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_SERVER } from '../../utils/config';


export const initialState = {
    user:null,
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:""
}

export const LoginAdmin = createAsyncThunk("user/loginAdmin", async (user, thunkAPI) => {
  try {
    const response = await axios.post(`${API_SERVER}/admin/login`, {
      email: user.email,
      password: user.password
    })
    return response.data
  } catch (error) {
    if(error.response){
      const message = error.response.data.msg
      return thunkAPI.rejectWithValue(message)
    }
  }
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers:(builder) =>{
    builder.addCase(LoginAdmin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(LoginAdmin.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user =  action.payload
    })
    builder.addCase(LoginAdmin.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload

    })
  }
});

export const {reset} = authSlice.actions

export default authSlice.reducer