import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import { Avatar, Logo } from '../../assets'
import { AiOutlineAppstore, AiOutlineHome } from 'react-icons/ai'
import { MdContacts, MdPermIdentity, MdPowerSettingsNew } from 'react-icons/md'
import { easeIn, motion } from 'framer-motion'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../../firebase/firebase.config'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { API_SERVER } from '../../utils/config'
import {GoTriangleDown, GoTriangleRight} from 'react-icons/go'






const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const {id} = useParams()

  const dispatch = useDispatch()
  
  const [products, setProducts] = useState([])
  const [showProductMenu, setShowProductMenu] = useState(false)
  
  useEffect(() => {

    getAllProducts()

    // window.addEventListener('click', (()=>{
    //   setshowProductMenu(false)
    // }))
  },[dispatch])
  
  const getAllProducts = (async()=> {
    const response = await axios.get(`${API_SERVER}/products`)
    const data = response.data.products
    setProducts(data)
  })


  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const hideMenu = () => {
    setShowMenu(false )
  }
  const togleProfileMenu = () =>{
    setShowProfileMenu(!showProfileMenu)
  } 

  const openProductMenu = () => {
    setShowProductMenu(true)
  }
  const closeProductMenu = () => {
    setShowProductMenu(false)
  }


  const logout = () => {
    signOut(auth).then(() => {
      console.log('Berhasil Logout')
    }).catch((error) => {
      console.log('Logout gagal')
    });
    
  }
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const location = useLocation();
  const isActive = (path) => {
    if (location.pathname === path) {
      return "text-gray-400";
    } else {
      return "";
    }
  };

  return (
    <>
    {/* mobile */}
    <header className={scrolled? 'w-screen z-50 fixed shadow-md shadow-black bg-white py-6 px-8 md:px-16 flex items-center justify-between  md:rounded-b-3xl':
    'relative md:fixed w-screen z-50 flex items-center justify-between py-6 px-8 md:px-16 bg-white shadow-md shadow-black md:shadow-none md:bg-transparent'}>
        
          {showProfileMenu && (
          <motion.nav 
          initial={{opacity:0,x:-200}}
          animate={{opacity:1, x:0}}
          exit={{opacity: 0, x:-200, transition: { ease: "easeInOut", duration: 1.3 } }}
          className="absolute h-screen w-1/2 bg-white mt-28 top-0 left-0 py-16 md:hidden z-30 rounded-b-xl shadow-md shadow-gray-500">
          <ul className="flex flex-col items-center justify-center gap-16 uppercase font-bold">
            
              <Link to="/" className={`text-2xl ${isActive('/')}`}>
                Beranda
              </Link>

              <Link to="/products" className={`text-2xl ${isActive('/products')}`}>
                Produk
              </Link>
            
              <Link to="/services" className={`text-2xl ${isActive('/services')}`}>
                Jasa
              </Link>
           
              <Link to="/about" className={`text-2xl ${isActive('/about')}`}>
                Tentang Kami
              </Link>
            
              <Link to="/contacts" className={`text-2xl ${isActive('/contacts')}`}>
                Kontak
              </Link>
           
          <button to='/login' className="text-2xl uppercase font-bold rounded-md border-2 px-5 py-2 border-black cursor-pointer hover:text-white hover:bg-black" onClick={logout}>
            Logout
          </button>
          </ul>
           
             
        </motion.nav>
          )}
       
        {/* mobile */}
        <Link to='/' className=''>
            <img src={Logo} className=' h-16 md:h-20' alt="" />
        </Link>
        <div className="flex md:hidden text-5xl rounded-md border-md border-2 border-black" onClick={toggleMenu}>
            <AiOutlineAppstore />
        </div>
       {showMenu && (
       <motion.nav 
         initial={{opacity:0,y:-200}}
         animate={{opacity:1, y:0}}
         exit={{opacity: 0, transition: { ease: "easeInOut", duration: 1.3 } }}
         className="absolute w-screen px-8 bg-white mt-28 top-0 left-0 py-16 md:hidden z-30 rounded-b-xl shadow-md shadow-gray-500">
         <ul className="flex w-full flex-col items-end gap-16 uppercase font-bold">
           
             <Link to="/" className={`text-2xl ${isActive('/')}`} onClick={toggleMenu}>
              Beranda
             </Link>

             
                  
                  {showProductMenu  ? (
                  <NavLink onClick={closeProductMenu} className='text-2xl cursor-pointer flex flex-col relative w-full'>
                      <div className={`w-full flex justify-between items-center ${isActive(`/products/${id}`)}`}>
                        <GoTriangleDown />
                        <h4 className={`${isActive(`/products/${id}`)}`}>Produk</h4>
                      </div>
                      <ul className='flex md:hidden flex-col w-full justify-center items-center opacity-100 text-black  mt-10 font-bold  gap-4'>
                        {products.map((product)=>(
                          <NavLink to={`products/${product.uuid}`} className='hover:border-b-2 py-1 hover:py-2 text-xl cursor-pointer' onClick={toggleMenu}>{product.name}</NavLink>
                        ))}
                      </ul>
                  </NavLink>
                  ):(
                  <NavLink onClick={openProductMenu} className={'text-2xl cursor-pointer relative w-full'}>
                    <div className={`w-full flex justify-between items-center ${isActive(`/products/${id}`)}`}>
                        <GoTriangleRight />
                        <h4 className={`${isActive(`/products/${id}`)}`}>Produk</h4>
                    </div>
                     <ul className='hidden md:flex flex-col opacity-0 w-80 -translate-x-1/2 bg-white text-black shadow-md left-1/2  mt-10 font-bold absolute  gap-4 p-5 rounded-b-md'>
                        {products.map((product)=>(
                          <li className='text-xl'>{product.name}</li>
                        ))}
                      </ul>
                  </NavLink>
                  )}
          
             <Link to="/services" className={`text-2xl ${isActive('/services')}`} onClick={toggleMenu}>
               Jasa
             </Link>
          
             <Link to="/about" className={`text-2xl ${isActive('/about')}`} onClick={toggleMenu}>
               Tentang Kami
             </Link>
           
             <Link to="/contacts" className={`text-2xl ${isActive('/contacts')}`} onClick={toggleMenu}>
               Kontak
             </Link>
          
         </ul>
         
        
       </motion.nav>
       )}
       {/* mobile */}

       {/* desktop */}
        <div className='hidden md:flex gap-8 items-center justify-center'>
            <ul className='hidden md:flex gap-8 uppercase font-bold'>
            <NavLink to="/" className={`text-2xl ${isActive('/')}`}>
               Beranda
             </NavLink>

            
              <NavLink className={`text-2xl cursor-pointer relative ${isActive(`/products/${id}`)}`}>
                  <h1 onMouseOver={openProductMenu} >Produk</h1>
                  {showProductMenu  ? (
                    <>
                      <div className='' onMouseOver={openProductMenu} onMouseOut={closeProductMenu}>
                      <ul className='hidden md:flex flex-col opacity-100 absolute w-80 -translate-x-1/2 bg-white text-black shadow-md shadow-black  left-1/2  mt-10 font-bold  gap-4 p-5 rounded-b-md'>
                        {products.map((product)=>(
                          <NavLink to={`products/${product.uuid}`} className='hover:border-b-2 py-1 hover:py-2 text-xl cursor-pointer' onClick={closeProductMenu}>{product.name}</NavLink>
                        ))}
                      </ul>
                      </div>
                    </>
                  ):(
                    <>
                    <div className='hidden'>
                     <ul className='hidden md:flex flex-col opacity-0 w-80 -translate-x-1/2 bg-white text-black shadow-md left-1/2  mt-10 font-bold absolute  gap-4 p-5 rounded-b-md'>
                        {products.map((product)=>(
                          <li className='text-xl'>{product.name}</li>
                        ))}
                      </ul>
                    </div>
                    </>
                  )}
                </NavLink>

           
          
           
             <NavLink to="/services" className={`text-2xl ${isActive('/services')}`}>
               Jasa
             </NavLink>
          
             <NavLink to="/about" className={`text-2xl ${isActive('/about')}`}>
               Tentang Kami
             </NavLink>
           
             <NavLink to="/contacts" className={`text-2xl ${isActive('/contacts')}`}>
               Kontak
             </NavLink>
            </ul>

              
        </div>
        {/* desktop */}
    </header>




    {/* <header className=' w-screen  fixed z-50 py-4 px-8 flex md:hidden bottom-0 mb-3 items-center justify-between  '>
            <ul className='flex gap-1 bg-white justify-between w-full items-center text-center uppercase font-bold rounded-md shadow-sm shadow-slate-500 py-2 px-4'>
                <div className='w-1/3  flex justify-between'>
                    <Link to='/' className=' flex flex-col items-center justify-center gap-0  p-2'>
                        <AiOutlineHome className='text-3xl' />
                        <p className="text-sm font-bold">Home</p>
                    </Link>
                    <Link to='/' className=' flex flex-col items-center justify-center gap-0  p-2'>
                        <AiOutlineHome className='text-3xl' />
                        <p className="text-sm font-bold">Home</p>
                    </Link>
                </div>
                <div className='relative w-1/12 bg-slate-700 h-3'>
                    <Link to='/about' className=' flex flex-col items-center justify-center gap-0 absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rounded-full h-28 w-28  min-h-36 min-w-36 bg-white shadow-sm shadow-gray-400  p-2'>
                        <BsShop className='text-5xl' />
                        <p className="text-sm font-bold">Store</p>
                    </Link>
                </div>
                <div className='w-1/3  flex justify-between'>
                    <Link to='/' className=' flex flex-col items-center justify-center gap-0  p-2'>
                        <IoMdHeartEmpty className='text-3xl' />
                        <p className="text-sm font-bold">Wishlist</p>
                    </Link>
                    <Link to='/' className=' flex flex-col items-center justify-center gap-0  p-2'>
                        <CiViewList className='text-3xl' />
                        <p className="text-sm font-bold">Transaksi</p>
                    </Link>
                </div>
            </ul>
    </header> */}
    </>
  )
}

export default Header