import React from 'react'
import { Slider } from '../../components'
import { aboutImage, tokoImage } from '../../assets'

const About = () => {
  return (
    <>
    <div className='w-screen max-h-[420px] bg-black' id='about_div'>
     <img src={aboutImage} className='max-h-[420px] w-full object-cover' alt=""/>
    </div>

    
    <section className='mx-auto container md:container-xl max-w-[1280px] my-10'>
      
      <div className='w-full md:h-510 flex flex-col md:flex-row justify-center md:justify-between gap-8'>
        <div className='w-full h-full bg-white rounded-3xl shadow-md shadow-gray-500 p-5 md:w-1/2 '>
          <h1 className='text-2xl md:text-4xl font-bold mb-5'>Profil Perusahaan</h1>
          <p className='text-justify text-lg md:text-3xl pr-4 font-normal'>Kami merupakan usaha dibidang
            konfeksi, sablon, dan bordir yang
            berdiri sejak tahun 2019.
            <br /> <br />Kami didirkan bukan hanya untuk
            penyedia barang dan jasa, tetapi kami
            didirakn untuk membantu kalian
            untuk mengekspresikan perasaan, ide,
            dan imaginasi pada tubuh kalian
            dengan produk kustom pakaian kami
            <br /> <br />Kami telah memilki pelanggan yang
            cukup banyak untuk membuat kustom
            pakaian untuk keperluan pribadi atau
            tim atau organisasi atau seragam
            perusahaan/usaha/bisnis mereka.
            <br /> <br /> Kami hadir untuk memudahkan kalian
            yang ingin membuat produk tanpa
            minimum order.
          </p>
        </div>
        <div className='w-full h-full flex-col gap-4 flex md:w-1/2 saturate-0'>
          <div className='w-full h-1/2 text-center flex flex-col justify-center bg-white shadow-md shadow-gray-500 rounded-3xl p-5'>
            <h1 className='text-2xl md:text-4xl font-bold mb-5'>
              Visi
            </h1>
            <p className=' text-lg md:text-3xl font-normal pr-4'>
            Menjadi perusahaan konfeksi
            yang terkemuka, terampil, dan
            terpercaya.
            </p>
          </div>
          <div className='w-full text-center flex flex-col justify-center bg-white shadow-md shadow-gray-500 rounded-3xl py-5 px-16'>
            <h1 className='text-2xl md:text-4xl font-bold mb-5'>Misi</h1>
            
            <ol className='list-disc text-justify text-lg md:text-3xl'>
              <li className='mb-5'>Menyediakan produk dan
                  jasa kualitas terbaik untuk
                  memenuhi kebutuhan dan
                  keinginan konsumen dalam
                  mengekspresikan ide-ide
                  mereka.
              </li>
              <li className='mb-5'>Menjaga stabilitas ekonomi
                  perusahaan-perusahaan di
                  sektor industri garmen.
              </li>
              <li className='mb-5'>
                Memberikan peluang
                kesempatan bekerja serta
                mengembangkan potensial
                diri pekerja dalam
                berinovasi.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    {/* <section className='w-screen h-52 bg-green-400 mt-32'></section> */}
    </>
  )
}

export default About