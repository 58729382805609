import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Slider } from '../../components';
import { db } from '../../firebase/firebase.config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import {BiTimer} from 'react-icons/bi'
import {GiCash, GiStarsStack} from 'react-icons/gi'
import { aboutImage, lummoLogo, shopeeLogo, tokoImage, tokopediaLogo } from '../../assets'


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";


// import required modules
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import { motion } from 'framer-motion';
import { API_SERVER } from '../../utils/config';

const Home = () => {
  
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [services, setServices] =  useState([])
  const [clients, setClients] = useState([])

  

  
  const breakPoints ={
    // 0: {
    //   slidesPerView: 3,
    //   // spaceBetween:40,
    // },
    640: {
      slidesPerView: 4,
      // spaceBetween:40,
    },
    768: {
      slidesPerView: 5,
      // spaceBetween: 40,
    },
    1024: {
      slidesPerView: 6,
      // spaceBetween: 40,
    },
  }
  
  
  useEffect( ()=> {
    // const fetchSliderItems = async () => {
    //   const data = await getDocs(collection(db, "categoryItems"));
    //     setCategory(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        
    //  }

    //  fetchSliderItems()
    getAllServices()
    
    getAllProducts()
    getAllClients()
  },[])

  const getAllServices = async () => {
    const response = await axios.get(`${API_SERVER}/services`)
    const data = response.data.services
    setServices(data)
  }

  
  const getAllClients = async () => {
    const response = await axios.get(`${API_SERVER}/clients`)
    const data = response.data.clients
    setClients(data)
    
  }


  const totalPages = Math.ceil(products.length / itemsPerPage)

  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return products.slice(indexOfFirstItem, indexOfLastItem);
  }
  

  const getAllProducts = async () => {
    const response = await axios.get(`${API_SERVER}/products`)
    const data = response.data.products
    setProducts(data)
  }


  return (
    <>
      <div className=''>
        <Slider />

        
        <section className="mb-10 container flex flex-col justify-center items-center mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold py-10">Produk</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-10 ">
            {getCurrentItems().map((product) => (
              <Link to={`products/${product.uuid}`} key={product.id} className="w-225 lg:w-300 xl:w-375 bg-white rounded-md overflow-hidden shadow-md shadow-gray-500 mx-auto relative">
                  <div className="relative w-full xl:h-375 pb-3/4">
                    <img className="h-full w-full object-cover" src={product.imageURL} alt={product.name} />
                  </div>
                  <div className="p-4 bg-gray-200">
                    <h2 className="text-black text-md md:text-2xl font-bold mb-2 text-center">{product.name}</h2>
                    {/* <p className="text-white text-sm md:text-xl mb-2">Category: {product.tbl_category.name}</p>
                    <p className="text-white font-bold text-md md:text-xl">Rp. {product.price.toLocaleString('id-ID')}</p> */}
                  </div>
              </Link>
            ))}
          </div>
          
         
          <div className="flex justify-center items-center mt-10">
          {Array.from(Array(totalPages).keys()).map((pageNumber) => (
            <button
              key={pageNumber}
              className={` w-10 h-10 mx-2 rounded-full flex justify-center items-center ${
              pageNumber + 1 === currentPage ? 'bg-black text-white' : 'bg-white text-gray-700'}`} 
              onClick={() => setCurrentPage(pageNumber + 1)}
            >
              <div>{pageNumber + 1}</div>
            </button>
          ))}
        </div>
        </section>

        <section className='container max-w-[1280px] my-10 gap-8 mx-auto p-10'>
          <h1 className=" text-center text-4xl md:text-6xl font-bold my-5">Jasa</h1>
          <div className='w-full h-full p-10'>
          <Swiper
            slidesPerView={2}
            spaceBetween={20}
            navigation={true}
            breakpoints={{
              640:{
                slidesPerView: 3,
                spaceBetween: 20,
              }
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper w-full h-150 sm:h-225 md:h-340 mx-auto"
          >
            {services.map((services) => (
              <SwiperSlide className='w-150 h-full rounded-lg relative cursor-pointer'
              key={services.id}
              >
                <img src={services.imageURL} alt="" className='w-full h-full object-cover rounded-lg'/>
                <motion.div 
                className='w-full h-full px-5 absolute bottom-0 left-0 flex flex-col gap-8 justify-center opacity-0 hover:opacity-80 items-center bg-black transition-all rounded-lg'
                initial={{y:-200}}
                animate={{y:0}}
                transition={{
                  duration:.3
                }}
                >
                  <h1 className='text-white text-3xl text-center'>{services.name}</h1>
                  <p className='text-center text-white' id='service_desc'>{services.description}</p>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
          </div>
        </section>

       
        <section className="py-20 ">
        <h1 className='text-center text-4xl md:text-6xl font-bold text-black'>Klien Kami</h1>
          <div className="container max-w-[1280px] py-20 mx-auto">
            <Swiper
              slidesPerView={2}
              loop={true}
              grabCursor={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                
                dynamicBullets:true
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              breakpoints={breakPoints}

              // className='swiper-pagination-bullet'
              className='mySwiper w-full h-150'
            >
              
              <div className='w-full h-full'>
                
              {clients.map((client) => (
                <SwiperSlide key={client.id} className='h-20'>
                  <div className='mx-auto h-full flex justify-center items-center'>
                    <div className='w-full h-full p-10'>
                      <img src={client.imageURL} alt={client.name} className="mx-auto h-20 object-contain" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              </div>     
            </Swiper>  
          </div>
        </section>
        <section className="my-10 container max-w-[1280px] mx-auto " >
          
          <div className='w-250 md:w-full h-150 rounded-lg bg-white shadow-md shadow-gray-500 relative border-2 border-black flex flex-col justify-center items-center'>
            <h1 className="text-2xl sm:text-4xl md:text-6xl text-center font-bold my-5 absolute -top-12 bg-white px-5">Ecommerce</h1>
            <div className='flex flex-row justify-center items-center w-full gap-8 sm:gap-24'>
              {/* <img className=' h-24 sm:h-32' src={lummoLogo}  alt="" /> */}
              <Link to='https://tokopedia.com/unecreate' target='blank' className="text-white">
                <img src={tokopediaLogo} alt="" className='h-24 sm:h-32' />
              </Link>
              <Link to='https://shopee.co.id/unecreate' target='blank' className="text-white">
                <img src={shopeeLogo} alt="" className='h-24 sm:h-32' />
              </Link>
            </div>
          </div>
        
        </section>
        
      </div>
      
    </>
  );
};


 {/* <div className='container max-w-[1280px] justify-center items-center flex flex-col md:flex-row w-full h-full gap-24'>
                <div className='w-340 h-340 bg-white mt-10 shadow-md shadow-gray-400 rounded-lg p-10 flex flex-col gap-8 justify-center items-center'>
                  <BiTimer className='text-9xl text-yellow-500 hover:text-gray-500'/>
                  <h1 className='text-4xl font-bold text-center'>Proses Cepat</h1>
                  <p className='text-2xl text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis pariatur harum ullam architecto. Impedit sed quaerat eos sint optio tenetur.</p>
                </div>
                <div className='w-340 h-340 bg-white mt-10 shadow-md shadow-gray-400 rounded-lg p-10 flex flex-col gap-8 justify-center items-center'>
                  <GiStarsStack className='text-9xl text-yellow-500 hover:text-gray-500'/>
                  <h1 className='text-4xl font-bold text-center'>Kualitas Terjamin</h1>
                  <p className='text-2xl text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis pariatur harum ullam architecto. Impedit sed quaerat eos sint optio tenetur.</p>
                </div>
                <div className='w-340 h-340 bg-white mt-10 shadow-md shadow-gray-400 rounded-lg p-10 flex flex-col gap-8 justify-center items-center'>
                  <GiCash className='text-9xl text-yellow-500 hover:text-gray-500'/>
                  <h1 className='text-4xl font-bold text-center'>Harga Terjangkau</h1>
                  <p className='text-2xl text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis pariatur harum ullam architecto. Impedit sed quaerat eos sint optio tenetur.</p>
                </div>
          </div> */}

export default Home;


