import React from 'react'
import {IoMdCheckmarkCircleOutline} from 'react-icons/io'
const Notif = (props) => {
  return (
    <>
    <div className='absolute z-50 w-350 h-150 bg-white flex flex-col items-center gap-8 justify-center p-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-md shadow-black'>
        {props.children}
    </div>
    </>
  )
}

export default Notif