import React, { useEffect, useState } from 'react'
import { MdCall, MdEmail, MdLocationOn, MdPhone } from 'react-icons/md'
import { Link, NavLink, useParams } from 'react-router-dom'
import { fbIcon, igIcon, LogoWhite, lummoLogo, shopeeLogo, tokopediaLogo, waIcon } from '../../assets'
import axios from 'axios'
import { API_SERVER } from '../../utils/config'


const Footer = () => {
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [showWa, setShowWa] = useState()

  useEffect(()=>{
    getAllClients()
    getAllProducts()
  },[])
  const getAllProducts = (async () => {
    const response = await axios.get(`${API_SERVER}/products`)
    const data = response.data.products
    setProducts(data)
  })
  const getAllClients = (async ()=>{
    const response = await axios.get(`${API_SERVER}/clients`)
    const data = response.data.clients
    setClients(data)
  })

  const onMoueOver = () => {
    setShowWa(true)
  }

  const onMouseOut = () => {
    setShowWa(false)
  }
  return (
    <>
    <footer className='w-screen bg-black py-10'>

      {/* desktop */}
      <div className='container-xxl hidden md:flex flex-col px-20'>
        <div className='grid grid-cols-4 py-10 border-b-2 gap-16'>
        <div className='mt-5 '>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Profil Perusahaan</h1>
          <img src={LogoWhite} alt="" className="mx-auto w-36 mb-5" />
          <p class="text-xl text-white text-justify mb-5">
          Kami merupakan usaha dibidang konfeksi, sablon, dan bordir yang berdiri sejak tahun 2019.
          </p>
          <ul className='flex items-center gap-8'>
            <Link to='https://wa.me/6287764426439' className="w-10 " target='_blank'><img src={waIcon} alt="" /></Link>
            <Link to="https://instagram.com/unecreate" className="w-10" target='_blank'><img src={igIcon} alt="" /></Link>
            <Link to="https://www.facebook.com/profile.php?id=100076238671676" className="w-10"><img src={fbIcon} alt="" target='_blank'/></Link>
          </ul>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Kontak Kami</h1>
          <ul className='flex flex-col gap-8'>
            <Link to='mailto:unecreateproject.ina@gmail.com' className="text-white flex gap-4">
              <div className='w-10 flex justify-center items-center'>
                <MdEmail className='w-10'/>
              </div>
              <h4 className='text-white text-xl'>unecreateproject.ina@gmail.com</h4>
            </Link>
            <Link to='tel:+6287764426439' className="text-white flex gap-4">
              <div className='w-10 flex justify-center items-center'>
                <MdPhone className='w-10'/>
              </div>
              <h4 className='text-white text-xl'>+6287764426439</h4>
            </Link>
            <Link to='https://maps.app.goo.gl/7TWUAaqSV9fYeNPn6' target='_blank' className="text-white flex gap-4">
              <div className='w-10 flex justify-center items-center'>
                <MdLocationOn className='w-10'/>
              </div>
              <h4 className='text-white text-xl text-justify'>
                PSP Jl. Markisa VI Blok J4 No.17 Pondok Sukatani Permai, 
                kec. Tapos, Kota Depok, Jawa Barat, Indonesia, 16454
              </h4>
            </Link>
          </ul>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Produk</h1>
          <ul className='flex flex-col justify-center items-center gap-8'>
            {products.map((product) => (
              <NavLink to={`products/${product.uuid}`} className="text-white">
                <h4 className='text-white text-xl'>{product.name}</h4>
              </NavLink>
            ))}
          </ul>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Ecommerce</h1>
          <ul className='flex items-center gap-8 justify-center'>
              <Link to='https://tokopedia.com/unecreate' target='blank' className="text-white">
                <img src={tokopediaLogo} alt="" className='h-16' />
              </Link>
              <Link to='https://shopee.co.id/unecreate' target='blank' className="text-white">
                <img src={shopeeLogo} alt="" className='h-16' />
              </Link>
              {/* <Link to='' className="text-white">
                <img src={lummoLogo} alt="" className='h-16' />
              </Link> */}
          </ul>
        </div>
        
        
        </div>
        <div className='text-center p-16'>
          <p className='text-white'>
            &copy; 2023 by UNECREATE. All rights reserved.
          </p>
        </div>
      </div>
      {/* desktop */}

      {/* mobile */}
      <div className='container-xxl flex md:hidden flex-col px-20'>
        <div className='grid grid-cols-1 py-10 border-b-2 gap-16'>
        <div className='mt-5 '>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Profil Perusahaan</h1>
          <img src={LogoWhite} alt="" className="mx-auto w-36 mb-5" />
          <p class="text-xl text-white text-justify mb-5">
          Kami merupakan usaha dibidang konfeksi, sablon, dan bordir yang berdiri sejak tahun 2019.
          </p>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Kontak Kami</h1>
          <ul className='flex flex-col justify-center items-center gap-8'>
            <Link to='mailto:unecreateproject.ina@gmail.com' className="text-white flex gap-4">
              <MdEmail className='w-10'/>
              <h4 className='text-white text-xl'>unecreateproject.ina@gmail.com</h4>
            </Link>
            <Link to='tel:+6287764426439' className="text-white flex gap-4">
              <MdPhone className='w-10'/>
              <h4 className='text-white text-xl'>+6287764426439</h4>
            </Link>
            <Link to='https://maps.app.goo.gl/7TWUAaqSV9fYeNPn6' target='_blank' className="text-white flex gap-4">
              <MdLocationOn className='w-10'/>
              <h4 className='text-white text-xl text-center'>
                PSP Jl. Markisa VI Blok J4 No.17 Pondok Sukatani Permai, 
                kec. Tapos, Kota Depok, Jawa Barat, Indonesia, 16454
              </h4>
            </Link>
          </ul>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Produk</h1>
          <ul className='flex flex-col justify-center items-center gap-8'>
            {products.map((product) => (
              <NavLink to={`products/${product.uuid}`} className="text-white">
                {product.name}
              </NavLink>
            ))}
          </ul>
        </div>
        <div className='mt-5'>
          <h1 className='text-white text-center text-3xl font-bold mb-10'>Ecommerce</h1>
          <ul className='flex items-center gap-8 justify-center'>
              <Link to='https://tokopedia.com/unecreate' className="text-white">
                <img src={tokopediaLogo} alt="" className='h-16' />
              </Link>
              <Link to='https://shopee.co.id/unecreate' className="text-white">
                <img src={shopeeLogo} alt="" className='h-16' />
              </Link>
              {/* <Link to='' className="text-white">
                <img src={lummoLogo} alt="" className='h-16' />
              </Link> */}
          </ul>
        </div>
        <div className="mt-5">
        <ul className='flex items-center justify-center gap-8'>
            <Link to='https://wa.me/6287764426439' className="w-10 " target='_blank'><img src={waIcon} alt="" /></Link>
            <Link to="https://instagram.com/unecreate" className="w-10" target='_blank'><img src={igIcon} alt="" /></Link>
            <Link to="https://www.facebook.com/profile.php?id=100076238671676" className="w-10"><img src={fbIcon} alt="" target='_blank'/></Link>
          </ul>
        </div>
        
        </div>
        <div className='text-center p-16'>
          <p className='text-white'>
            &copy; 2023 by UNECREATE. All rights reserved.
          </p>
        </div>
      </div>
      {/* mobile */}

    </footer>
    <Link to={'https://wa.me/6287764426439'} className='fixed bottom-5 rounded-full z-50 right-5 flex gap-4 justify-center items-center bg-green-400 px-10 py-5' onMouseOver={onMoueOver} onMouseOut={onMouseOut}>
      <img src={waIcon} className=' w-10 h-10 md:w-14 md:h-14' alt="" />
      {
        showWa && (
          <h2 className='font-bold text-xl md:text-2xl text-white'>Hubungi Kami</h2>
        )
      }
     
    </Link>
    </>
  )
}

export default Footer