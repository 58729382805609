
import 'swiper/css'
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import React, { useState, useEffect } from 'react';
import '../index.css'
import axios from 'axios';
import { style } from 'motion';
import { motion } from 'framer-motion';
import { API_SERVER } from '../utils/config';



const Slider = () => {
  const [swiper, setSwiper] = useState(null);
  const [sliderItems, setSliderItems] = useState([])

  
  
  
  useEffect( ()=> {
    getAllSlider()
  },[])
  
  const getAllSlider = async () => {
    const response = await axios.get(`${API_SERVER}/sliders`)
    setSliderItems(response.data.sliders)
  }
  return (
    <Swiper
     pagination={{
      clickable: true,
      bulletActiveClass: "custom-bullet-active",
      renderBullet: function (index, className) {
        return '<span class="' + className + ' custom-pagination-bullet"></span>';
      },
    }}
    grabCursor={true}
    modules={[Pagination, EffectFade,Autoplay]}
    className="mySwiper"
    onSwiper={setSwiper}
    autoplay={{
      delay: 3000, 
      disableOnInteraction: false 
    }}
  >
          {sliderItems.map(n =>(
            <SwiperSlide
             key={n.uuid}
             className='relative '>
            <div className="swiper-slide-content relative">
              <div className="absolute opacity-40 w-full h-full"></div>
                <div className='absolute bottom-0 h-full right-0 m-auto px-4 lg:px-24 flex justify-center items-center' style={{maxWidth:"40vw", minWidth:"10vw"}}>
                  <div className="text-right">
                    <motion.h1 
                    initial={{y:-100}}
                    animate={{y:0}}
                    transition={{duration:1.5}}
                    className="font-bold text-right uppercase text mb-2" style={{fontSize:'4vw',  textShadow: '2px 2px 4px #000000', fontFamily:'Montserrat'}}>
                      {n.caption}
                    </motion.h1>
                    <motion.p 
                    initial={{y:100}}
                    animate={{y:0}}
                    transition={{duration:1.5}}
                    className='text-justify mb-2' style={{fontSize:'1vw'}}>
                      {n.text}
                    </motion.p>
                    <motion.button 
                     initial={{y:100}}
                     animate={{y:0}}
                     transition={{duration:1.5}}
                      className="get-started-button bg-black rounded-2xl mb-2 text-white sm:text-xs uppercase md:text-lg px-2 py-1 md:px-6 md:py-2" style={{fontSize:'1vw'}}>
                      Get Started
                    </motion.button>
                  </div>
                </div>
              <motion.img 
              initial={{opacity:0}}
              animate={{opacity:1}}
              exit={{opacity:0}}
              transition={{duration:1.5}}
              src={n.imageURL} alt='slide-1' className='' />
            </div>
            
            {/* <Pagination /> */}
          </SwiperSlide>
          
          ))}    
        </Swiper>
  )
}

export default Slider