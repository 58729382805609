import { Routes, Route } from 'react-router';
import { About, Contacts, DisplayProduct, Home, Products, Register, Services } from './pages';
import { Error, Layout } from './components';


function App() {


  return (
    <>
    <Routes>
      <Route path='/register' element={<Register />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="products/:id" element={<DisplayProduct />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  </>
  );
}

export default App;
