import { motion } from 'framer-motion'
import React from 'react'
import { errorBackground, errorImage } from '../../assets'


const Error = () => {
  return (
    <motion.div
    className="grid place-items-center h-screen text-center bg-white text-black"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{
      backgroundImage: `url(${errorBackground})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  >
    <motion.img
     initial={{ y: -200 }}
     animate={{ y: 0 }}
     transition={{ type: 'spring', stiffness: 100, delay:.3 }}
    src={errorImage} />
    <motion.p
      className="text-2xl font-semibold"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      Sorry, we couldn't find the page you were looking for.
    </motion.p>
    <motion.a
      href="/"
      className="inline-block mt-4 py-2 px-4 border border-black rounded-md font-semibold uppercase tracking-wider text-xs hover:bg-black  hover:text-white transition-all duration-300 ease-in-out"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.4 }}
    >
      Back to Home
    </motion.a>
  </motion.div>
  )
}

export default Error