import React from 'react'
import { Logo, lummoLogo, shopeeLogo, tokopediaLogo } from '../../assets'
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md'
import { Link } from 'react-router-dom'


const Contacts = () => {
  return (
    <>
    <div className='w-screen h-52'></div>
    <section className='container-xxl'>
      <div className='w-full bg-white rounded-md shadow-xl hidden md:flex gap-24 justify-between p-10 mb-20'>
        <div className='w-1/2 rounded-md'>
          <iframe className='w-full h-510 rounded-md'alt=""  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.9839919385568!2d106.88133587559895!3d-6.396064093594519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebf786ce03bb%3A0x86ccfbfda27d4f00!2zVU7Di0NSRUFURSBLb25mZWtzaSDigKIgU2FibG9uIOKAoiBCb3JkaXI!5e0!3m2!1sen!2sid!4v1687161946368!5m2!1sen!2sid" ></iframe>
        </div>
        <div className='w-1/2 display flex flex-col justify-between'>
          <h1 className='text-4xl font-bold border-b-2 pb-2'>Kontak Kami</h1>
          <img src={Logo} className='h-52 mx-auto' alt="" />
          <p className=' text-[1.8rem] text-gray-500 text-justify'></p>
          <div className='flex gap-8 flex-col justify-between'>
              <a href='https://maps.app.goo.gl/7TWUAaqSV9fYeNPn6' target='_blank' className=' h-20 flex gap-4 items-center justify-start text-4xl'>
                <div className="w-16 flex justify-center items-center">
                    <MdLocationOn className='w-full' />
                </div>
                <p>Jl. Markisa VI Blok J4 No.17 Pondok Sukatani Permai, Kec. Tapos, Kota Depok, Jawa Barat, Indonesia, 16454</p>
              </a>
              <a href="mailto:unecreateproject.ina@gmail.com" className=' h-20 flex items-center justify-start gap-4 text-4xl'>
                <div className=" w-16 flex justify-center items-center">
                    <MdEmail className='w-full' />
                </div>
                <p>unecreateproject.ina@gmail.com</p>
              </a>
              <a href="tel:+6287764426439" className=' h-20 flex gap-4 text-4xl items-center justify-start'>
                <div className=" w-16 flex justify-center items-center">
                    <MdPhone className='w-full' />
                </div>
                <p>+62 877 644 264 39</p>
              </a>
          
          </div>
        </div>
      </div>
      
      {/* mobile */}
      <div className='w-full bg-white rounded-md shadow-xl md:hidden flex flex-col gap-24 justify-between p-10 mb-20'>
        <div className='w-full rounded-md'>
          <iframe className='w-full h-510 rounded-md'alt=""  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.9839919385568!2d106.88133587559895!3d-6.396064093594519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebf786ce03bb%3A0x86ccfbfda27d4f00!2zVU7Di0NSRUFURSBLb25mZWtzaSDigKIgU2FibG9uIOKAoiBCb3JkaXI!5e0!3m2!1sen!2sid!4v1687161946368!5m2!1sen!2sid" ></iframe>
        </div>
        <div className='w-full display flex flex-col gap-24 justify-between'>
          <h1 className='text-4xl font-bold border-b-2 pb-2'>Kontak Kami</h1>
          <img src={Logo} className='h-52 mx-auto' alt="" />
          <p className=' text-[1.8rem] text-gray-500 text-justify'></p>
          <div className='flex gap-8 flex-col justify-between'>
              
              <a href='https://maps.app.goo.gl/7TWUAaqSV9fYeNPn6' target='_blank' className='flex items-center  gap-8 text-4xl'>
                <div className=" w-16 h-16 ">
                    <MdLocationOn className='w-full' />
                </div>
                <p>Jl. Markisa VI Blok J4 No.17 Pondok Sukatani Permai, Kec. Tapos, Kota Depok, Jawa Barat, Indonesia, 16454</p>
              </a>
              <a href="mailto:unecreateproject.ina@gmail.com" className='flex items-center  gap-8 text-4xl'>
                <div className=" w-16 h-16 ">
                    <MdEmail className='h-full'/>
                </div>
                <p>unecreateproject.ina@gmail.com</p>
              </a>
              <a href="tel:+6287764426439" className='flex items-center  gap-8 text-4xl'>
                <div className=" w-16 h-16 ">
                    <MdPhone className='h-full'/>
                </div>
                <p>+62 877 644 264 39</p>
              </a>
          
          </div>
        </div>
      </div>
      {/* mobile */}
    </section>
    </>
  )
}

export default Contacts