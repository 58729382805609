import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { lummoLogo, shopeeLogo, tokopediaLogo } from '../../assets'
import { API_SERVER } from '../../utils/config'

const DisplayProduct = () => {
    const [product, setProduct] = useState([])
    const {id} = useParams()

    useEffect(()=>{

      getProductById()
      },[])
    useEffect(()=>{

    getProductById()
    },[id])

    const getProductById = async () => {
        const response = await axios.get(`${API_SERVER}/products/${id}`)
        const data = response.data.product
        setProduct(data)
      }
    
  return (
    <>
    <div className='w-screen h-52'></div>
    <section className='container-xxl'>
      <div className='w-full bg-white rounded-md shadow-xl hidden md:flex gap-8 justify-between p-10 mb-20'>
        <div className='w-1/2 rounded-md'>
          <img src={product.imageURL} className='w-full rounded-md'alt="" />
        </div>
        <div className='w-1/2 display flex flex-col gap-8'>
          <h1 className='text-4xl border-b-2 pb-2'>{product.name}</h1>
          <h1 className='font-bold text-2xl'>Deskripsi Produk:</h1>
          <p className=' text-[1.8rem] text-gray-500 text-justify'>{product.description}</p>
          <div className='flex gap-8 items-center'>
              <h1 className='text-2xl font-bold'>
                Pesan Sekarang Melalui:
              </h1>
              <a href={product.tokopediaLink} target='blank' className=' h-20'>
                <img src={tokopediaLogo} alt="" className=' h-20'/>
              </a>
              <a href={product.shopeeLink} target='blank' className=' h-20'>
                <img src={shopeeLogo} alt="" className=' h-20'/>
              </a>
          
          </div>
        </div>
      </div>

      {/* mobile */}
      <div key={id} className='w-full bg-white rounded-md shadow-xl flex flex-col md:hidden gap-8 justify-between p-10 mb-20'>
        
          <h1 className='text-4xl border-b-2 pb-2 text-center font-bold'>{product.name}</h1>
          <div className='w-full rounded-md'>
            <img src={product.imageURL} className='w-full rounded-md'alt="" />
          </div>
          <h1 className='font-bold text-2xl'>Deskripsi Produk:</h1>
          <p className=' text-[1.5rem] text-gray-500 text-justify'>{product.description}</p>
          <div className='flex gap-8 items-center'>
              <h1 className='text-lg font-bold'>
                Pesan Sekarang Melalui:
              </h1>
              <a href={product.tokopediaLink} className=' h-16'>
                <img src={tokopediaLogo} alt="" className=' h-16'/>
              </a>
              <a href={product.shopeeLink} className=' h-16'>
                <img src={shopeeLogo} alt="" className=' h-16'/>
              </a>
          </div>
      </div>
      {/* mobile */}
    </section>
    </>
  )
}

export default DisplayProduct