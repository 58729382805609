import React, { useEffect, useState } from 'react'
import { Link, useNavigate, } from 'react-router-dom'
import { googleLogo, loginBackground, LogoWhite } from '../../assets'
import { SlLock } from 'react-icons/sl'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { CiMail } from 'react-icons/ci'
import { motion } from 'framer-motion'
import { Loader, Notif } from '../../components'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import {auth} from '../../firebase/firebase.config'
import { CgDanger } from 'react-icons/cg'
import axios from 'axios'





const Register = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [nameError, setNameError] = useState('')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [notif, setNotif] = useState(false)
  const [alertStatus, setAlertStatus] = useState('danger')
  const [msg, setMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [passwordError,setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)


  const navigate = useNavigate()
 

  const registerUser =  async(e) => {
    e.preventDefault();
    setIsLoading(true)
    // Reset error messages
   setTimeout(() => {
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setIsLoading(false)
   },1000)

   let isValid = true;

    if (!firstName.trim() || !lastName.trim()) {
      setNameError('Nama depan dan belakang harus diisi');
      isValid = false;
    }

    if (!email.trim()) {
      setEmailError('Email harus diisi');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Email tidak valid');
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError('Password harus diisi');
      isValid = false;
    } else if (password.trim().length < 8) {
      setPasswordError('Password kurang dari 8 karakter');
      isValid = false;
    }

    if (!confirmPassword.trim() || confirmPassword.trim() !== password.trim()) {
      setConfirmPasswordError('Password tidak sama');
      isValid = false;
    }
   
    if(isValid) {
        await createUserWithEmailAndPassword(auth,email, password)
      .then((userCredential) => {
        const user = userCredential.user
        updateProfile(user, {
          displayName:firstName + ' ' + lastName
        })
        setNotif(true)
        setAlertStatus('success')
        setMsg('Registrasi Berhasil')
        setTimeout(()=>{
          setNotif(false)
          navigate('/login')
        },2000)
        console.log(user)
      })
      .catch((error) => {
        const errorCode = error.code
        console.log(errorCode)
        if(errorCode === 'auth/email-already-in-use'){
          setNotif(true)
          setAlertStatus('danger')
          setMsg('Email sudah digunakan')
          setTimeout(() =>{
            setNotif(false)
            setIsLoading(false)
          },1000)
        } else {
          setNotif(true)
          setAlertStatus('danger')
          setMsg('Registrasi gagal')
          setTimeout(() =>{
            setNotif(false)
          },2000)
        }
      })
    }
      
  }
  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const variants = {
    hidden: { x: 300, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };
  return (
    <>
    {notif && (
      
      <Notif>
          {alertStatus === 'success' ? (

            <IoMdCheckmarkCircleOutline className=' text-6xl text-green-400' />
          ) :(
            <CgDanger className=' text-6xl text-red-400'/>
          )}
          <h1 className='text-4xl text-center'>{msg}</h1>
      </Notif>
    )}
    <div className='w-screen h-screen relative z-30 flex items-center blur-md justify-center'>
        <img src={loginBackground} className='w-screen h-screen object-cover' alt="" />
    </div>
    <div className='w-screen h-screen absolute z-40 flex items-center left-0 top-0 justify-center px-5'>
      <motion.div
          //  initial={{ y: -200 }}
          //  animate={{ y: 0 }}
          //  transition={{ type: 'spring', stiffness: 100, delay:.3 }}
          className='w-[1200px] max-w-[1200px] h-650  bg-white flex items-center justify-between shadow-md shadow-black rounded-lg overflow-hidden'>
              <div className=' w-3/5 bg-black max-w-3/4 h-full flex items-center justify-center rounded-l-lg relative'>
                  <img src={loginBackground} className='w-full h-full object-cover rounded-l-lg' alt="" />
                  <div className='w-full h-full absolute bg-gradient-to-b from-black to-white bottom-0 left-0 opacity-20 rounded-l-lg flex items-center justify-center'>
                      
                  </div>
                  <Link to='/' className=' absolute rounded-md left-1/2 bottom-0 bg-white -translate-x-1/2 -translate-y-1/2 uppercase text-lg hover:bg-black hover:text-white font-bold  py-3 px-6 cursor-pointer'>back to home</Link>
              </div>
              
              <div className='w-2/5 h-full bg-black flex items-center flex-col gap-8 justify-center rounded-r-lg py-5 px-16 relative'>

                {isLoading && (
                  <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                    <Loader />
                  </div>
                )}
                 
              <motion.img
                    src={LogoWhite}
                    className="w-52"
                    alt=""
                  
                    initial={{ y: -50, opacity: 0}}
                    animate={{ y: 0, opacity: 1}}
                    transition={{ delay: 0.2, duration: 0.5}}
                  />
                  <motion.h1
                    className="text-5xl text-center font-bold text-white"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                  >
                    REGISTER
                  </motion.h1>
                  <form onSubmit={registerUser} className='w-full flex-col gap-4 flex'>
                    <div className='w-full flex-col items-center gap-4'>
                        <motion.div
                          className="w-full flex justify-between items-center mb-5"
                          variants={variants}
                          initial="hidden"
                          animate="visible"
                          transition={{ delay: 0.4, duration: 0.5 }}
                        >
                          <div className='border-b-2 border-white rounded-md text-2xl'>
                            <input
                              type='text'
                              className=" px-4 py-2 text-white focus:outline-none bg-transparent placeholder:text-gray-400 valid:text-white"
                              placeholder="Nama depan"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              
                            />  
                          </div> 
                          <div className='border-b-2 border-white rounded-md text-2xl flex flex-col'> 
                            <input
                              type='text'
                              className=" px-4 py-2 text-white focus:outline-none bg-transparent placeholder:text-gray-400 valid:text-white"
                              placeholder="Nama belakang"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div> 
                        </motion.div>
                        <p className='text-red-400'>{nameError}</p>
                      </div>
                    <div className='w-full flex-col items-center gap-4'>
                      <motion.div
                        className="w-full border-b-2 border-white rounded-md text-2xl flex flex-center items-center mb-5"
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.4, duration: 0.5 }}
                      >
                        <CiMail className="text-white" />
                        <input
                          type='text'
                          className="w-full px-4 py-2 text-white focus:outline-none bg-transparent placeholder:text-gray-400 valid:text-white"
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          
                        />
                      </motion.div>
                      <p className='text-red-400'>{emailError}</p>
                    </div>

                    <div className='w-full flex-col items-center gap-4'>
                      <motion.div
                        className="w-full text-2xl flex flex-center items-center mb-5 border-b-2 border-white rounded-md"
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.5, duration: 0.5 }}
                      >
                        <SlLock className="text-white" />
                        <input
                          type={showPassword ? "text" : "password"}
                          className="px-4 py-2 w-full focus:outline-none bg-transparent placeholder:text-gray-400 valid:text-white"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Tab") {
                              e.preventDefault();
                              document.getElementById("confirmPasswordInput").focus();
                            }
                          }}
                        />
                        <div onClick={togglePassword}>
                          {showPassword ? (
                              <ImEye className="text-white" />
                              ):(
                              <ImEyeBlocked className="text-white" />
                            )}
                          </div>
                      </motion.div>
                      <p className='text-red-400'>{passwordError}</p>
                    </div>

                    <div className='w-full flex-col items-center gap-4'>
                      <motion.div
                        className="w-full text-2xl flex flex-center items-center mb-5 border-b-2 border-white rounded-md"
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.6, duration: 0.5 }}
                      >
                        <SlLock className="text-white" />
                        <input
                          id='confirmPasswordInput'
                          type={showConfirmPassword ? "text" : "password"}
                          className="px-4 py-2 w-full focus:outline-none bg-transparent placeholder:text-gray-400 valid:text-white"
                          placeholder="Konfirmasi Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      <div onClick={toggleConfirmPassword}>
                      {showConfirmPassword ? (
                          <ImEye className="text-white" />
                          ):(
                          <ImEyeBlocked className="text-white" />
                        )}
                      </div>
                        
                      </motion.div>
                      <p className='text-red-400'>{confirmPasswordError}</p>
                    </div>
                    
                    <motion.button 
                    variants={variants}
                    type='submit'
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className='w-full text-2xl bg-white text-center py-3 rounded-lg uppercase font-bold'
                    >
                        Register
                    </motion.button>
                  </form>
                  <motion.button 
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: 0.8, duration: 0.5 }}
                  className='w-full text-2xl bg-white text-center py-3 rounded-lg uppercase font-bold flex justify-center items-center gap-4'>
                      <img src={googleLogo} className='w-8' alt="" />
                      Login With Google
                  </motion.button>
                  <motion.div 
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: 0.9, duration: 0.5 }}
                  className='w-full'>
                      <h1 className='text-lg text-white'>Have any account? <Link to='/login' className='text-yellow-600 text-xl font-bold text-start hover:text-yellow-500'>Login</Link></h1>
                  </motion.div>
              </div>
      </motion.div>
    </div>
    
    </>
   
  )
}

export default Register