import React, { useEffect, useState } from 'react'
import { bgService } from '../../assets'
import axios from 'axios'
import { API_SERVER } from '../../utils/config'

const Services = () => {
  const [services, setServices] = useState([])
  useEffect(()=>{
    getAllServices()
  },[])

  const getAllServices = async () => {
    const response = await axios.get(`${API_SERVER}/services`)
    const data = response.data.services
    setServices(data)
  }
  return (
    <>
      <div className='w-screen h-32 ' >
      </div>
      
      {services.map((service, index) => (
        <>
        {index % 2 !== 0 ? (
          <section className='w-screen bg-white px-16'>

            {/* desktop */}
            <div className='mx-auto max-w-[1024px] py-16 hidden sm:flex items-center gap-16'>
              <div className="w-1/2 flex-col">
                <h1 className=" text-6xl md:text-8xl font-bold mb-10">{service.name}</h1>
                <p className='text-xl md:text-3xl text-justify'>
                  {service.description}
                </p>
              </div>
              <div className="w-1/2">
                <img src={service.imageURL} className='w-full h-225 md:h-420 object-cover' alt="" />
              </div>
            </div>
            {/* desktop */}

            {/* mobile */}
            <div className='mx-auto max-w-[1024px] py-16 flex sm:hidden flex-col justify-center items-center gap-4'>
              
                <h1 className=" text-4xl font-bold mb-10 text-center">{service.name}</h1>
                <img src={service.imageURL} className=' w-full h-225 object-cover mb-10' alt="" />
                <p className='text-xl md:text-3xl text-justify'>
                  {service.description}
                </p>
            </div>
            {/* mobile */}
        </section>
        ):(
          <section className='w-screen bg-gray-100 px-16'>
            {/* desktop */}
            <div className='mx-auto max-w-[1024px] py-16 hidden sm:flex items-center gap-16'>
              <div className="w-1/2">
                <img src={service.imageURL} className='w-full h-225 md:h-420 object-cover' alt="" />
              </div>
              <div className="w-1/2 flex-col">
                <h1 className=" text-6xl md:text-8xl font-bold mb-10">{service.name}</h1>
                <p className='text-xl md:text-3xl text-justify'>
                  {service.description}
                </p>
              </div>
            </div>
            {/* desktop */}

            {/* mobile */}
            <div className='mx-auto max-w-[1024px] py-16 flex sm:hidden flex-col justify-center items-center gap-4'>
              
                <h1 className=" text-4xl font-bold mb-10 text-center">{service.name}</h1>
                <img src={service.imageURL} className='w-full h-225 object-cover mb-10' alt="" />
                <p className='text-xl md:text-3xl text-justify'>
                  {service.description}
                </p>
            </div>
            {/* mobile */}
          </section>
        )}
        </>
      ))}
    </>
  )
}

export default Services