import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAPnO1OApeKisQgqq2pZjmiGoL2KoMAL9I",
    authDomain: "unecreate-62033.firebaseapp.com",
    databaseURL: "https://unecreate-62033-default-rtdb.firebaseio.com",
    projectId: "unecreate-62033",
    storageBucket: "unecreate-62033.appspot.com",
    messagingSenderId: "618247045631",
    appId: "1:618247045631:web:2af8ae1eb3bce54502a7ca"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)



export { firebaseConfig, app ,db, auth, storage}