import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom' 
import './Layout.module.scss'
const Layout = () => {
  return (
    <div className='h-screen w-screen bg-gray-50 layout relative'>
        <Header />
        <Outlet />
        <Footer />
    </div>
  )
}

export default Layout